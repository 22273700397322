<template>
  <div class="order">
    <div class="row row-equal">
      <!-- <div class="flex md12 sm12 xs12"> -->
      <div class="flex md4 sm4 xs4">
        <va-input v-model="qrcode" label="订单编号" placeholder="二维码编号" />
      </div>
      <div class="flex md4 sm4 xs4">
        <va-select
          :options="typeList"
          v-model="desc"
          label="订单类型"
          :max-height="null"
          no-clear
        />
      </div>
      <div class="flex md4 sm4 xs4">
        <va-date-picker label="起始时间" v-model="start_date" />
      </div>
      <div class="flex md4 sm4 xs4">
        <va-date-picker label="结束时间" v-model="end_date" />
      </div>
    </div>
    <!-- </div> -->

    <div class="row row-equal">
      <va-button @click="readItems(1)">提交查询</va-button>
    </div>
    <!-- </div> -->
    <div class="table">
      <va-card title="订单详情">
        <va-data-table
          :fields="fields"
          :data="items"
          :loading="loading"
          :totalPages="totalPages"
          @page-selected="readItems"
          api-mode
        ></va-data-table>
      </va-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcode: "",
      start_date: "",
      end_date: "",
      perPage: 5,
      totalPages: 0,
      items: [],
      //     items:[
      //     {
      //         "username": "sa_shzx",
      //         "qrcode": "1234",
      //         "desc": "\u7535\u5b50\u7248",
      //         "created": "2021-02-02 13:00:48.754872",
      //         "money": "15"
      //     },
      // ],
      loading: false,
      desc: "",
      typeList: ["电子版", "打印版"],
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "序号",
          width: "15%",
        },
        {
          name: "qrcode",
          title: "对应编号",
          width: "20%",
        },
        {
          name: "money",
          title: "订单金额(元）",
        },
        {
          name: "desc",
          title: "订单种类",
        },
        {
          name: "created",
          title: "订单创建时间",
          width: "20%",
        },
        {
          name: "username",
          title: "收银员",
        },
      ];
    },
  },
  methods: {
    readItems(page = 1) {
      this.loading = true;

      //   const params = {
      //     per_page: this.perPage,
      //     page: page,
      //   };

      this.axios
        .post(
          "/v1/data/orders",
          {
            qrcode: this.qrcode,
            desc: this.desc,
            start_date: this.start_date,
            end_date: this.end_date,
            per_page: this.perPage,
            page: page,
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage["access_token"],
            },
          }
        )
        .then((res) => {
          this.items = res.data["data"];
          this.totalPages = res.data["totalPages"];

          console.log(res.data["data"]);
          //   this.items = response.data.data;
          //   this.totalPages = response.data.total_pages;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
